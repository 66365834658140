// src/components/Dashboard.js
import React, { useState, useEffect, useCallback } from 'react';
import { useApolloClient, gql } from '@apollo/client';
import Modal from './Modal';
import moment from 'moment';

const LIST_OUTLET_QUERY = gql`
  query listOutlet {
    listOutlet {
      kodeoutlet
      produk
      deviceId
      deviceLoginAt
      createdAt
      lastChanged
      statusid
      token
    }
  }
`;

const GENERATE_TOKEN_MUTATION = gql`
  mutation generateToken($product: String!) {
    generateToken(product: $product) {
      kodeoutlet
      token
      qrcode
    }
  }
`;

const Dashboard = () => {
  const client = useApolloClient();
  const [outlets, setOutlets] = useState([]);
  const [modalData, setModalData] = useState(null);

  const fetchOutlets = useCallback(async () => {
    const { data } = await client.query({ query: LIST_OUTLET_QUERY });
    setOutlets(data.listOutlet);
  }, [client]);

  useEffect(() => {
    fetchOutlets();
  }, [fetchOutlets]);

  const handleGenerateToken = async (product) => {
    const { data } = await client.mutate({
      mutation: GENERATE_TOKEN_MUTATION,
      variables: { product },
      refetchQueries: [{ query: LIST_OUTLET_QUERY }], // Refetch the listOutlet query after mutation
    });

    setModalData(data.generateToken);
  };

  const handleCloseModal = () => {
    setModalData(null);
    fetchOutlets(); // Refresh data after closing the modal
  };

  return (
    <div className="container">
      <button className="fixed-button" onClick={() => handleGenerateToken('bukanota')}>Generate Token</button>
      <h1>Dashboard</h1>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Kode Outlet</th>
              <th>Produk</th>
              <th>Device ID</th>
              <th>Device Login At</th>
              <th>Created At</th>
              <th>Last Changed</th>
              <th>Status ID</th>
              <th>Token</th>
            </tr>
          </thead>
          <tbody>
            {outlets.map((outlet) => (
              <tr key={outlet.kodeoutlet}>
                <td>{outlet.kodeoutlet}</td>
                <td>{outlet.produk}</td>
                <td>{outlet.deviceId}</td>
                <td>{outlet.deviceLoginAt && moment(+outlet.deviceLoginAt).format("DD MMM YYYY HH:mm:ss")}</td>
                <td>{moment(+outlet.createdAt).format('DD MMM YYYY HH:mm:ss')}</td>
                <td>{moment(+outlet.lastChanged).format('DD MMM YYYY HH:mm:ss')}</td>
                <td>{outlet.statusid}</td>
                <td>{outlet.token}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalData && <Modal data={modalData} onClose={handleCloseModal} />}
    </div>
  );
};

export default Dashboard;
