// src/components/Modal.js
import React from 'react';

const Modal = ({ data, onClose }) => (
  <div className="modal">
    <div className="modal-content">
      <span className="close" onClick={onClose}>&times;</span>
      <h2>Generated Token</h2>
      <p>Serial Number: {data.token}</p>
      <img src={data.qrcode} alt="QR Code" />
    </div>
  </div>
);

export default Modal;
