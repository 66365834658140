// src/components/Login.js
import React, { useState } from 'react';
import { useApolloClient, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const LOGIN_QUERY = gql`
  query login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      clientID
      token
    }
  }
`;

const Login = () => {
  const client = useApolloClient(); // Get the Apollo Client instance
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [, setLoginData] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await client.query({
        query: LOGIN_QUERY,
        variables: { username, password },
      });
      setLoginData(data.login);
      localStorage.setItem('clientID', data.login.clientID);
      localStorage.setItem('token', data.login.token);
      navigate('/dashboard');
    } catch (error) {
      setLoginError(error.message);
    }
  };

  return (
    <div className="container">
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" className="login-button">
          Login
        </button>
        {loginError && <p>Error: {loginError}</p>}
      </form>
    </div>
  );
};

export default Login;
